<template>
    <div class="ml-1">
        <div>
            <h3>{{ user[0].name }}</h3>
            <p class="btn-group">
                <span v-if="user[0].mobile_number"><i class="fa fa-phone text-primary"></i> {{ user[0].mobile_number }}</span>
            </p>
        </div>
        <div class="row" v-if="addresses.data.length > 0">
            <address-card v-for="(address, index) in addresses.data" :key="index" :address="address"></address-card>

        </div>
        <div v-else>
            <span>No address</span>
        </div>
        <div class="card mt-3 bs-4 ">
            <!--            <simple-table :fields="fields" :data="data">-->
            <!--                <template #orderid="{rowData}">-->
            <!--                    <span> #{{ rowData.id }}</span>-->
            <!--                </template>-->
            <!--                <template #total_quantity="{rowData}">-->
            <!--                    <span> {{ rowData.total_quantity }} items</span>-->
            <!--                </template>-->
            <!--                <template #date="{rowData}">-->
            <!--                    {{ getDate(rowData.created_at) }}-->
            <!--                </template>-->
            <!--                <template #address="{rowData}">-->
            <!--                    {{ rowData.address_id.address_line_1 }}<br/>-->
            <!--                    {{ rowData.address_id.address_line_2 }}<br/>-->
            <!--                </template>-->

            <!--                &lt;!&ndash;                <template slot="actions" slot-scope="item">&ndash;&gt;-->
            <!--                &lt;!&ndash;                    <div class="btn-group">&ndash;&gt;-->
            <!--                &lt;!&ndash;                        <btn color="primary" size="sm" @click="viewItem(item.rowData)" text="Edit">&ndash;&gt;-->
            <!--                &lt;!&ndash;                        </btn>&ndash;&gt;-->
            <!--                &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--                &lt;!&ndash;                </template>&ndash;&gt;-->

            <!--            </simple-table>-->

            <vue-table-card class=""
                            :fields='fields'
                            :url="listUrl" :show-search-box="false" :show-pagination="true"
                            :page-sizes="false" :per-page="10" :extra-params="ext" ref="table">

                <template #orderid="{rowData}">
                    <span> #{{ rowData.id }}</span>
                </template>
                <template #total_quantity="{rowData}">
                    <span> {{ rowData.total_quantity }} items</span>
                </template>
                <template #date="{rowData}">
                    {{ getDate(rowData.created_at) }}
                </template>
                <template #address="{rowData}">
                    {{ rowData.address_id.address_line_1 }}<br/>
                    {{ rowData.address_id.address_line_2 }}<br/>
                </template>
                <template #landmark="{rowData}">
                    {{ rowData.address_id.landmark }}<br/>
                </template>
                <template #pinCode="{rowData}">
                    {{ rowData.address_id.pin_code }}<br/>
                </template>
                <template #actions="{rowData}">
                    <btn color="info" size="sm" text="View" @click="openModal(rowData,'delivered')"/>

                </template>
            </vue-table-card>

        </div>
        <modal title="Order Items" ref="modal" width="50r">
            <div class="col-md-12">
                <div>
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Items</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Total Price</th>
                        </tr>
                        <tr v-for="(i, orderItem) in orderItems.data" v-bind:key="orderItem">
                            <td>{{ i.name }}</td>
                            <td>{{ i.quantity }}</td>
                            <td>{{ i.selling_price }}</td>
                            <td>{{ i.total_price }}</td>
                        </tr>
                        <tr>
                            <td colspan="2"></td>
                            <td>Total</td>
                            <td colspan="3" v-if="orderItems.total_amount">
                                {{orderItems.total_amount[0].total_amount}}
                            </td>
                        </tr>
                        </thead>
                    </table>
                </div>

            </div>
        </modal>
    </div>
</template>

<script>
import AddressCard from '@/views/customers/customerComponents/addressCard';
import debounce from 'lodash.debounce';
// import axios from 'secure-axios';
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name       : 'customerDetails',
    components : { AddressCard },
    props      : {
        data      : { type : Array },
        firstUser : {
            type     : Array,
            required : false
        },
        addresses : {
            type     : Object,
            required : false
        },
        userID : {
            type     : Number,
            required : false
        }
    },
    mounted () {
        this.user = [...this.firstUser];
        this.orderData = [...this.data];
        this.UserAddress = [...this.addresses];
    },
    afterMount () {
        this.getdate();
    },
    watch : {
        userID : debounce(function () {
            this.loadData();
        }, 500),

        firstUser () {
            this.user = [...this.firstUser];
        },
        data () {
            this.orderData = [...this.data];
        },
        addresses () {
            this.UserAddress = [...this.addresses];
        }

    },
    data () {
        return {
            ext : {
                user_id : this.userID,
                status  : 'delivered'
            },
            orderItems  : [],
            listUrl     : urls.users.preOrder,
            perPage     : 0,
            currentPage : 1,
            fields      : [
                {
                    name       : '__slot:orderid',
                    sortField  : '',
                    title      : 'Order ID',
                    titleClass : ''
                },
                {
                    name       : 'total_amount',
                    sortField  : '',
                    title      : 'Price',
                    titleClass : ''
                },
                {
                    name       : '__slot:total_quantity',
                    sortField  : '',
                    title      : 'No Of Items',
                    titleClass : ''
                },
                {
                    name       : '__slot:date',
                    sortField  : '',
                    title      : 'Date and time',
                    titleClass : ''
                },
                {
                    name       : '__slot:address',
                    sortField  : '',
                    title      : 'Address',
                    titleClass : ''
                },
                {
                    name       : '__slot:landmark',
                    sortField  : '',
                    title      : 'Landmark',
                    titleClass : ''
                },
                {
                    name       : '__slot:pinCode',
                    sortField  : '',
                    title      : 'Pincode',
                    titleClass : ''
                },
                {
                    name       : '__slot:actions',
                    sortField  : '',
                    title      : '',
                    titleClass : ''
                }
            ],
            user        : [],
            UserAddress : [],
            orderData   : []
        };
    },
    methods : {
        getDate (datetime) {
            const current = new Date(datetime);
            const longMonth = current.toLocaleString('en-us', { month : 'long' });
            const date = current.getDate() + ' ' + longMonth + ' ' + current.getFullYear();
            const time = current.getHours() + ':' + current.getMinutes();
            let time1 = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
            if (time1.length > 1) { // If time format correct
                time1 = time1.slice(1); // Remove full string match value
                time1[5] = +time1[0] < 12 ? 'am' : 'pm'; // Set AM/PM
                time1[3] = ' ';
                time1[0] = +time1[0] % 12 || 12; // Adjust hours
            }
            time1 = time1.join('');
            const dateTime = time1 + ', ' + date;
            return dateTime;
        },
        async openModal (item, status) {
            this.loading = false;
            const response = await axios.get(urls.orders.orderList, {
                params : {
                    order_id   : item.id,
                    user_id    : item.user_id,
                    address_id : item.address_id,
                    status     : status
                }
            });
            if (response) {
                this.orderItems = response.data.orderItems;
            }
            this.loading = false;
            this.$refs.modal.show();
        }
        // async loadData () {
        //     this.loading = true;
        //     const response = await axios.get(urls.users.details, {
        //         params : {
        //             id : this.userID
        //         }
        //     });
        //     this.loading = false;
        //     this.setData(response);
        // },
        // setData (response) {
        //     if (response.data) {
        //         this.user = response.data.user;
        //         this.UserAddress = response.data.Addresses;
        //         this.userID = response.data.user[0].id;
        //         this.orderData = response.data.orders;
        //     }
        // }
    }
};
</script>

<style scoped>
</style>
