<template>
    <div class="col-md-4">
        <div class="card bs-4" style="min-height:120px">
            <div class="fl-x mb-2">
                <i class="bl-8 mr-2 b-primary"></i>
                <h6 class="m-0">
                    <span class="pr-5">Delivery Address</span>
                </h6>
                <span v-if="address.is_default =='1'" class="backgroundColor">Default Address</span>
            </div>
            <div class="fl-x">
                <i class="fa fa-map-marker text9-primary"></i>
                <p class="fl-eqh-l">

                    <span v-if="address.address_line_1">{{ address.address_line_1 }}</span>
                    <span v-if="address.address_line_2">{{ address.address_line_2 }}</span>
                    <span v-if="address.address_line_3">{{ address.address_line_3 }}</span>
                    <span v-if="address.street">{{ address.street }}</span>
                    <span v-if="address.landmark">{{ address.landmark }}</span>
                    <span v-if="address.district">{{ address.district }}</span>
                    <span v-if="address.state">{{ address.state }}</span>
                    <span v-if="address.pin_code">{{ address.pin_code }}</span>
                </p>

            </div>
        </div>

    </div>
</template>

<script>
export default {
    name  : 'addressCard',
    props : {
        address : {
            type     : Object,
            required : false
        }
    }
};
</script>

<style scoped>
</style>
