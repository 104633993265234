<template>
    <div class="fl-te-c pb-2 pt-2 bb-1 p-2 cursor-pointer" @click="$emit('click')">
        <p class="fl-x-cl m-1" >{{ user.name }}</p>
    </div>

</template>

<script>
export default {
    name  : 'nameCard',
    props : {
        user : { type : Object }
    }
};
</script>

<style scoped>
.shadow {
    box-shadow: 0 4px 24px 0 rgb(0 0 0 / 20%);
}
</style>
