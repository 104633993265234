<template>
    <div>
        <div class="card text-center" v-if="loading">
            <loading-animation/>
            <h6 class="pl-2">Please wait while data is being loaded...</h6>
        </div>
        <div v-else class="row">
            <div class="col-md-2 br-1 p-0">
                <div class="fl-te-c p-2">
                    <h3 class="text-primary">Users</h3>
                </div>
                <validated-input class="mt-2 p-2" v-model="search"></validated-input>
                <div>
                    <infinite-scroll-container @load-next="loadNext">
                    <div v-for="(user, index) in users"  :key="index"  class="position-div hover-bg-3"
                         :class="{'active':activeCategoryIdx===index}">
                        <name-card :user="user" @click="FilterCustomer(user,index)"></name-card>
                    </div>
                    </infinite-scroll-container>
                </div>
            </div>
            <div class="col-md-10">
                <div class="card text-center" v-if="loadingSecond">
                    <loading-animation/>
                    <h6 class="pl-2">Please wait while data is being loaded...</h6>
                </div>
                <div v-else>
                    <customer-details :addresses="UserAddresses" :data="ordersList" :userID="user_id"
                                      :firstUser="UserDetails"></customer-details>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NameCard from '@/views/customers/customerComponents/nameCard';
import CustomerDetails from '@/views/customers/customerComponents/customerDetails';
import InfiniteScrollContainer from '@components/InfiniteScroll';
import axios from 'secure-axios';
import urls from '@/data/urls';
import debounce from 'lodash.debounce';

export default {
    name       : 'customers',
    components : {
        CustomerDetails,
        NameCard,
        InfiniteScrollContainer
    },
    data () {
        return {
            activeCategoryIdx : 0,
            users             : [],
            UserDetails       : [],
            UserAddresses     : [],
            ordersList        : [],
            user_id           : null,
            page              : 1,
            isLast            : false,
            loading           : false,
            loadingSecond     : false,
            search            : '',
            model             : {
                name : ''

            }
        };
    },
    watch : {
        search : debounce(function () {
            this.users = [];
            this.page = 0;
            this.isLast = false;
            this.loadNext();
        }, 500)
    },
    beforeMount () {
        this.loadFirstData();
    },
    methods : {
        async FilterCustomer (item, index) {
            const id = item.id;
            console.log('item of user', id);
            this.user_id = id;
            this.activeCategoryIdx = index;
            this.loading = false;
            this.loadingSecond = true;
            const response = await axios.get(urls.users.Customers, {
                params : {
                    user_id : item.id
                }
            });
            this.loading = false;
            this.loadingSecond = false;
            if (response.data) {
                this.UserDetails = response.data.data;
                this.UserAddresses = response.data.customersadress;
                this.ordersList = response.data.orders.data;
            }
        },
        async loadNext () {
            if (this.loading) {
                return;
            }
            if (this.isLast) {
                return;
            }
            this.page += 1;
            await this.loadData();
        },
        async loadData () {
            this.loading = false;
            const response = await axios.get(urls.users.Customers, {
                params : {
                    show_first : true,
                    search     : this.search,
                    id         : 1,
                    page       : this.page,
                    per_page   : 10

                }
            });
            // this.users = response.data.data;
            this.loading = false;

            if (response) {
                if (response.data.data.length > 0) {
                    this.users.push(...response.data.data);
                }
            }
            // this.setData(response); // edited
        },
        async loadFirstData () {
            this.loading = true;
            const response = await axios.get(urls.users.Customers, {
                params : {
                    show_first : true,
                    page       : this.page,
                    per_page   : 10

                }
            });
            this.loading = false;
            this.setData(response);
        },
        setData (response) {
            if (response.data) {
                console.log('if (response.data) - Users Listing', response);
                this.users = response.data.data;
                this.UserDetails = response.data.data;
                this.UserAddresses = response.data.customersadress;
                this.user_id = response.data.customersadress.data[0].user_id;
                console.log('userid', this.user_id);
                this.ordersList = response.data.orders.data;
                console.log('orderlist', this.ordersList);
            }
        }

    }
};
</script>

<style scoped>

</style>
